<!-- =========================================================================================
  File Name: CouponList.vue
  Description: Subscription List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/subscription/pixinvent
========================================================================================== -->

<template>

  <div id="page-subscription-list">

    <div class="vx-card p-6">

      <h4 class="mb-6" v-if="cardTitle"> {{ cardTitle }}</h4>

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown class="cursor-pointer" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }} - {{
                  subscriptionsData.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : subscriptionsData.length
                }} of {{ subscriptionsTotal }}</span>
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          </vs-dropdown>

          <vs-button color="primary" class="ml-2 round" type="gradient"
                     @click="createNewCouponPopup = !createNewCouponPopup">Create New Coupon
          </vs-button>

        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input @input="updateSearchQuery" class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                  placeholder="Search..." v-model="searchQuery"/>
        <vs-button @click="gridApi.exportDataAsCsv()" class="mb-4 md:mb-0">Export as CSV</vs-button>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="subscriptionsData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" v-model="currentPage"/>

    </div>

    <vs-popup :active.sync="createNewCouponPopup" title="Create New Coupon" id="CreateCouponPopup">
      <vs-input v-model="createNewCouponValues.name" class="mb-8 w-full" label-placeholder="Coupon Name"/>
      <vs-input v-model="createNewCouponValues.code" class="mb-8 w-full" label-placeholder="Coupon Code"/>
      <vs-input v-model="createNewCouponValues.amount_off" class="mb-8 w-full" label-placeholder="Amount Off"/>

      <h4 class="mb-3">Off Type</h4>
      <vs-radio v-model="createNewCouponValues.amount_type" vs-value="percent" class="mr-4" vs-name="layout-type-vertical">% (Percent)</vs-radio>
      <vs-radio v-model="createNewCouponValues.amount_type" vs-value="dollar" class="mr-4 mb-6" vs-name="layout-type-horizontal">$ (Dollar)</vs-radio>


      <h4 class="mb-2">Max Uses</h4>
      <div class="flex items-center mb-6">
        <vs-input-number v-model="createNewCouponValues.max_uses"/>
      </div>

      <h4 class="mb-3">Coupon Expiry</h4>
      <div class="flex items-center mb-6">
        <datepicker placeholder="Select Date" v-model="createNewCouponValues.date_expires"></datepicker>
        <vs-button class="ml-1" color="danger" icon="icon icon-x-circle" icon-pack="feather">Remove Expiry</vs-button>
      </div>


      <vs-button c color="success" type="filled" @click="createCoupon" icon="icon icon-plus-circle" icon-pack="feather">Create Coupon</vs-button>
    </vs-popup>

  </div>

</template>

<script>
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';

// Store Module
// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue';
import CellRendererActions from './cell-renderer/CellRendererActions.vue';
import CellRendererDate from './cell-renderer/CellRendererDate.vue';
import CellRendererNumber from './cell-renderer/CellRendererNumber.vue';
import CellRendererProduct from './cell-renderer/CellRendererProduct.vue';
import CellRendererCoupon from './cell-renderer/CellRendererCoupon.vue';


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,
    CellRendererDate,
    CellRendererNumber,
    CellRendererProduct,
    CellRendererCoupon
  },
  props: {
    cardTitle: {
      type: String,
      default: null,
      required: false
    },
    productIds: {
      type: Array|String,
      default: '',
      required: false
    },
    userId: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {

      subscriptionsData: [],
      subscriptionsTotal: 0,

      searchQuery: '',

      createNewCouponPopup: false,
      createNewCouponValues: {
        name: '',
        code: '',
        amount_off: '',
        amount_type: '',
        max_uses: 0,
        product_ids: [],
        date_expires: 0
      },

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            let value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          sortable: true,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: 'Products',
          field: 'product_ids',
          filter: true,
          sortable: true,
          cellRendererFramework: 'CellRendererProduct',
        },
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          sortable: true,
        },
        {
          headerName: 'Amount Off',
          field: 'amount_off',
          filter: true,
          cellRendererFramework: 'CellRendererCoupon',
        },
        {
          headerName: 'Max Uses',
          field: 'max_uses',
          filter: true,
          cellRendererFramework: 'CellRendererNumber',
        },
        {
          headerName: 'Current Uses',
          field: 'current_uses',
          filter: true,
          cellRendererFramework: 'CellRendererNumber',
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
        },
        {
          headerName: 'Expires',
          field: 'date_expires',
          filter: true,
          sortable: true,
          sort: 'desc',
          cellRendererFramework: 'CellRendererDate',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions,
      },
      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadCouponBuffer(val);
      },
    },
  },
  methods: {
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = {type: 'equals', filter: val};
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    loadCouponBuffer(page = 0) {

      if (page === 0) {
        page = this.currentPage;
      }

      const partnerId = this.activeUserInfo.partner_id;


      this.$http.get(`billing/coupons/counts?amount=${this.paginationPageSize}&user_id=${this.userId}&product_ids=${this.productIds}&partner_id=${partnerId}`)
        .then(response => {

          if (response.data.data) {

            const data = response.data.data;

            if (data.pages) {
              this.totalPages = data.pages;
              this.subscriptionsTotal = data.total;
            }

          }
        })
        .catch(exception => {

        });

      this.$http.get(`billing/coupons?amount=${this.paginationPageSize}&page=${page}&user_id=${this.userId}&product_ids=${this.productIds}&partner_id=${partnerId}`)
        .then(response => {

          if (response.data.data) {
            this.subscriptionsData = response.data.data;
          }

          this.gridApi.sizeColumnsToFit();
        })
        .catch(exception => {

          let error = 'An unknown error occurred while loading the subscription list';
          if (exception.response) {
            error = exception.response.data.error.description;
          }

          return this.$vs.notify({
            title: 'Failed to load subscription list',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    createCoupon () {

      const partnerId = this.activeUserInfo.partner_id;
      const maxUse = this.createNewCouponValues.max_use

      let pl = {
        name: this.createNewCouponValues.name,
        code: this.createNewCouponValues.code,
        amount_off: this.createNewCouponValues.amount_off,
        amount_type: this.createNewCouponValues.amount_type,
        max_uses: maxUse,
        partner_id: partnerId,
        date_expires: 0,
      }

      this.$http.post('billing/coupons', pl)
        .then(response => {
          this.$vs.loading.close()
          this.loadCouponBuffer()
          if (response.status === 200) {
            this.createNewCouponPopup = false

            return this.$vs.notify({
              color: 'success',
              title: 'Coupon Created',
              text: 'You have successfully created a coupon',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right'
            })
          }

        }).catch(exception => {
        this.$vs.loading.close()
        return this.$vs.notify({
          color: 'danger',
          title: 'Failed to create coupon',
          text: exception.response.data.error.description,
          icon: 'feather',
          iconPack: 'icon-x-circle',
          position: 'top-right'
        })
      })
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
  created() {
    this.loadCouponBuffer()

  },
};

</script>

<style lang="scss">
#page-subscription-list {
  .subscription-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

#CreateCouponPopup {
  input.vs-inputx.vs-input--input {
    background: #1B1E22!important;
    border: 1px solid #ffffff!important;

    &:focus {
      border: 1px solid rgba(var(--vs-primary),1)!important;
    }
  }
}


</style>
