<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    {{this.params.data.amount_off}} {{ this.params.data.amount_type === 'dollar' ? '$' : '' }}{{ this.params.data.amount_type === 'percent' ? '%' : '' }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererCurrency'
};
</script>
